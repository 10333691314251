<template>
    <div class="field richtext-editor-container"
        :class="{
            'is-invalid': !!errorMessage,
            'flush': flush
        }">
        <label v-if="label" :for="idAttr" class="form-label">{{ label }}</label>
        <div class="form-control-wrapper">
            <CKEditorComponent 
                v-if="editor"
                :id="idAttr"
                :editor="editor"
                :model-value="inputValue"
                :config="editorConfig"
                :disabled="disabled"
                @input="onInput"
                @blur="handleBlur">
            </CKEditorComponent>
        </div>
        <p v-show="errorMessage" class="error-message caption text-danger">{{ errorMessage }}</p>
        <p v-if="!!maxlength" class="text-muted mt-4"><small>{{ charactersLeft }} characters left</small></p>
    </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent, computed, ref } from 'vue';
import { useField } from 'vee-validate';
import type ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import kebabCase from 'lodash/kebabCase';

const CKEditorComponent = defineAsyncComponent(() => {
    // @ts-ignore as CKEditor has no typescript support for Vue
    return import('@ckeditor/ckeditor5-vue').then(x => x.component)
})

let editor = ref<typeof ClassicEditor|null>(null);
import('@ckeditor/ckeditor5-build-classic').then(x => editor.value = x.default);

const props = defineProps({
    id: String,
    name: { type: String, required: true },
    label: { type: String },
    maxlength: { type: Number },
    placeholder: { type: String },
    disabled: Boolean,
    flush: Boolean,
    maxHeight: Number
})

const maxHeightCss = computed(() => !props.maxHeight ? undefined : `${props.maxHeight}px`);

const idAttr = computed(() => props.id ?? 'rte-' + kebabCase(props.name));

const emit = defineEmits([
    'update:modelValue'
])

const {
    value: inputValue,
    handleBlur,
    handleChange,
    errorMessage
} = useField<string>(props.name);

const charactersLeft = computed(() => Math.max((props.maxlength ?? 0) - (inputValue.value?.length ?? 0), 0));

let editorConfig: any = {
    title: props.label,
    toolbar: [ 'bold', 'italic', '|', 'bulletedList', 'numberedList' ],
    placeholder: props.placeholder
}

const onInput = function (content: string) {
    emit('update:modelValue', content)
    handleChange(content)
}

</script>

<style lang="scss">
    .richtext-editor-container {
        .ck.ck-content.ck-editor__editable {
            transition: $btn-transition;
            outline: 2px solid transparent;
            outline-offset: -2px;
            max-height: v-bind(maxHeightCss);
    
            &.ck-focused,
            &:focus {
                outline-color: $primary;
                box-shadow: $btn-focus-box-shadow;
            }
        }
        // .richtext-editor-container .ck.ck-content.ck-editor__editable.ck-focused, .richtext-editor-container .ck.ck-content.ck-editor__editable:focus
        
        &.flush {
            --ck-color-base-background: transparent;
            --ck-color-toolbar-background: transparent;

            .ck.ck-toolbar {
                border: 0;
            }

            .ck.ck-content.ck-editor__editable {
                box-shadow: none;
                
                &:not(.ck-focused) {
                    border: 0;
                }
                
                &.ck-focused {
                    box-shadow: none;
                    &:not(.ck-editor__nested-editable) {
                        border: 0;
                    }
                }
            }
        }

        &.is-invalid .ck.ck-content.ck-editor__editable {
            border-color: $danger;

            &.ck-focused,
            &:focus {
                outline-color: $danger;
                box-shadow: 0 0 0 $input-btn-focus-width rgba($danger, $input-btn-focus-color-opacity);
            }
        }
    }
</style>
